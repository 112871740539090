<template>
	<div class="home">
		<cibSubsidiary></cibSubsidiary>
	</div>
</template>

<script>
import cibSubsidiary from '@/components/pages/report/cibSubsidiary/cibSubsidiaryRequest.vue';

export default {
	components: {
		'cibSubsidiary': cibSubsidiary,
	}
};
</script>

<style>

</style>
